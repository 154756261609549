import {formOptionsPreset} from "@/shared/fields";
export const formOptionsGen = () => {
  return [
    [
      {
        name: '노출상태', key: 'shelf_state', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'true', variant: 'success'},
          {text: '미노출', value: 'false', variant: 'warning'}
        ]
      },
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: '생성시각', value: '_cdt', variant: 'primary'},
          {text: '변경시각', value: '_mdt', variant: 'primary'},
          {text: '발란코드', value: 'goods_no', variant: 'info'},
          {text: 'GM ID', value: 'gm_id', variant: 'info'},
        ]
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
      {name: '검수 상태', key: 'audit_state', options: [
          {text: '전체', value: 'ALL', variant: 'light'},
          {text: '검수중', value: 'Pending review'},
          {text: '검수 완료', value: 'Approval successful', variant: 'primary'},
          {text: '검수 실패', value: 'Approval failed', variant: 'warning'},
          {text: '삭제', value: 'Withdrawn', variant: 'danger'},
        ]},
    ],
  ];
};

export const formFieldsGen = () => {
  return [
    {group: '상품속성'},
    {name: '발란코드', key: 'goods_no', type: 'number'},
    {name: '상품명', key: 'skc_title', type: 'string', width: 180},
    {name: 'Sku ID', key: 'supplier_code', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 150},
  ];
};

export const formIncExcGen = () => {
  return [
    {name: '발란코드', key: 'goods_no', type: 'number'},
    {name: 'GM ID', key: 'gm_id', type: 'string'},
    {name: 'Sku ID', key: 'supplier_code', type: 'string'},
    {name: 'skc code', key: 'skc_name', type: 'string'},
  ];
}
